import React from 'react';


const Signin = React.lazy(() => import('./Components/Authentication/SignIn/SignIn1'));
const ResetPassword1 = React.lazy(() => import('./Components/Authentication/ResetPassword/ResetPassword1'));
const ChangePassword = React.lazy(() => import('./Components/Authentication/ChangePassword'));

const route = [
    { path: '/auth/signin', exact: true, name: 'Signin 1', component: Signin },
    { path: '/auth/reset-password', exact: true, name: 'Reset Password ', component: ResetPassword1 },
    { path: '/auth/change-password', exact: true, name: 'Change Password ', component: ChangePassword },
];

export default route;